<!--
 * @Description:人行道停车运营管理 运营管理 欠费记录 parkLackRecords
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-11-27 11:56:38
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <el-row class="topSelectLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌号">
              <el-input v-model="searchForm.plateNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场">
              <el-select v-model="searchForm.parkId"
                         clearable
                         filterable
                         placeholder="全部">
                <el-option v-for="item in parkNameList"
                           :key="item.value"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="进场时间">
              <el-date-picker v-model="entranceTime"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="出场时间">
              <el-date-picker v-model="leaveTime"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="topButton">
        <el-button type="primary"
                   @click="queryButton">查 询</el-button>
        <el-button type="info"
                   @click="resetForm"
                   style="margin-left:17px;">重 置</el-button>
      </el-row>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>欠费记录</span>
        <el-button type="info"
                   class="tableTitleButton"
                   @click="exportFile">
          导 出
        </el-button>
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button @click="handleClickWatch(scope.row)"
                             type="text"
                             size="medium">详情</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <el-dialog title="欠费记录详情"
               :visible.sync="dialogVisible"
               append-to-body>
      <el-row>
        <el-col :span="12">
          <el-row class="lineItem">订单编号 : {{lineRowItem.orderSequence}}</el-row>
          <el-row class="lineItem">停车场名称 : {{lineRowItem.parkName}}</el-row>
          <el-row class="lineItem">车牌号 : {{lineRowItem.plateNumber}}</el-row>
        </el-col>
        <el-col :span="12">
          <el-row class="lineItem">进场时间 : {{lineRowItem.entranceTime}}</el-row>
          <el-row class="lineItem">出场时间 : {{lineRowItem.leaveTime}}</el-row>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { dtTable },
  //监听属性 类似于data概念
  computed: {},
  data () {
    //这里存放数据
    return {
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      listMap: { pageNum: 1, pageSize: 15, },
      tableList: { list: [] },
      searchForm: {}, // 搜索内容
      pageNum: 1, // 分页
      pageSize: 15,
      tableColumnList: [
        {
          prop: 'plateNumber',
          label: '车牌号',
        },
        {
          prop: 'parkName',
          label: '停车场',
        },
        {
          prop: 'arrangeAmount',
          label: '欠费金额（元）',
        },
        {
          prop: 'entranceTime',
          label: '进场时间',
        },
        {
          prop: 'leaveTime',
          label: '出场时间',
        },
      ],
      parkNameList: [],//停车场
      orderCreateTime: null,//生成时间
      entranceTime: null,// 进场时间
      leaveTime: null, // 出场时间
      dialogVisible: false, //查看弹窗
      lineRowItem: {},
      // 时间快速选择
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryTableList()
    this.queryParkList()
  },
  //方法集合
  methods: {
    // 导出
    exportFile () {
      this.$axios.post(this.$downloadBaseUrl + "order/downloadArrangeOut", this.searchForm, {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        }
      }).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "欠费记录.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },
    //获取表格数据
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$parkLackRecords.queryListByPage(this.searchForm).then(res => {
        if (res.resultEntity) {
          this.tableList.list = res.resultEntity.content
          let list = res.resultEntity.content
          list.forEach(item => {
            item.arrangeAmount = Number(Number(item.arrangeMoney) / 100).toFixed(2)
          });
          this.paginationConfig.total = res.resultEntity.totalElements
        }
        else {
          this.tableList.list = []
        }
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList();
    },
    //查询按钮
    queryButton () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      if (this.entranceTime) {
        this.searchForm.entranceStartTime = this.entranceTime[0]
        this.searchForm.entranceEndTime = this.entranceTime[1]
      }
      else {
        this.searchForm.entranceStartTime = ''
        this.searchForm.entranceEndTime = ''
      }
      if (this.leaveTime) {
        this.searchForm.leaveStartTime = this.leaveTime[0]
        this.searchForm.leaveEndTime = this.leaveTime[1]
      }
      else {
        this.searchForm.leaveStartTime = ''
        this.searchForm.leaveEndTime = ''
      }
      this.queryTableList();
    },
    //重置
    resetForm () {
      this.searchForm = {}
      this.entranceTime = null
      this.leaveTime = null
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    // 操作中的查看按钮
    handleClickWatch (row) {
      this.lineRowItem = row
      this.dialogVisible = true
    },
    // 停车场
    queryParkList () {
      let info = {
        columnName: ['park_id', 'park_name'], //有且只有两个 id放前面名称放后面
        tableName: 'tb_park',
        whereStr: [
          {
            "colName": "park_type_code",
            "value": "2"
          }
        ]
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        let data = response.resultEntity
        this.parkNameList = data
      })
    },
  },

  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
//@import url(); 引入公共css类
.mainBody {
  height: 100%;
  .topSelectLine {
    @include background('blockBg');
    width: 100%;
    height: $topSelectHeight;
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    @include background('blockBg');
    width: 100%;
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.lineItem {
  margin-top: 10px;
  color: #79baf7;
  font-size: 16px;
}
</style>
